// Generated by Framer (716dd6f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["rOaWVXU5L"];

const variantClassNames = {rOaWVXU5L: "framer-v-1tedcxf"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};


function toResponsiveImage_194x2gw(value) {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

function useRandomID(){const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; image?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "rOaWVXU5L", image: uVePwMMsf = {src: new URL("assets/AYHKw2j8EBgmaIhTCNyFlh97dM0.jpg", import.meta.url).href, srcSet: `${new URL("assets/512/AYHKw2j8EBgmaIhTCNyFlh97dM0.jpg", import.meta.url).href} 512w, ${new URL("assets/1024/AYHKw2j8EBgmaIhTCNyFlh97dM0.jpg", import.meta.url).href} 1024w, ${new URL("assets/AYHKw2j8EBgmaIhTCNyFlh97dM0.jpg", import.meta.url).href} 1290w`}, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "rOaWVXU5L", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = useRandomID()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-9aAcw", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? undefined}}>
<Image {...restProps} background={{alt: "", fit: "fit", intrinsicHeight: 1015, intrinsicWidth: 1290, pixelHeight: 1015, pixelWidth: 1290, ...toResponsiveImage_194x2gw(uVePwMMsf)}} className={cx("framer-1tedcxf", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"rOaWVXU5L"} ref={ref} style={{filter: "grayscale(1)", WebkitFilter: "grayscale(1)", ...style}} transition={transition}/>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-9aAcw [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-9aAcw * { box-sizing: border-box; }", ".framer-9aAcw .framer-19acwti { display: block; }", ".framer-9aAcw .framer-1tedcxf { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 773px; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 1080px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-9aAcw .framer-1tedcxf { gap: 0px; } .framer-9aAcw .framer-1tedcxf > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-9aAcw .framer-1tedcxf > :first-child { margin-left: 0px; } .framer-9aAcw .framer-1tedcxf > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 773
 * @framerIntrinsicWidth 1080
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"uVePwMMsf":"image"}
 */
const FramermM5yUe08j: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramermM5yUe08j;

FramermM5yUe08j.displayName = "Sparetime register 2";

FramermM5yUe08j.defaultProps = {height: 773, width: 1080};

addPropertyControls(FramermM5yUe08j, {uVePwMMsf: {__defaultAssetReference: "data:framer/asset-reference,AYHKw2j8EBgmaIhTCNyFlh97dM0.jpg?originalFilename=image.jpg&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramermM5yUe08j, [])